import React, {FunctionComponent} from 'react';
import {graphql, PageProps} from 'gatsby';
import Sidebar from '../components/Sidebar';
import HeroImage from '../components/HeroImage';
import {MDXRenderer} from 'gatsby-plugin-mdx';
import LoginWrapper from '../components/LoginWrapper';
import HtmlHead from '../components/layout/HtmlHead';
import MDX from '../types/MDX';
import OneTrustManager from '../lib/otscript';

export type MarkdownPageProps = PageProps<{
    mdx: MDX;
}>;

const ContentPage: FunctionComponent<MarkdownPageProps> = (props) => {
    require('../styles/global.scss');
    require(`../styles/${process.env.THEME}/global.scss`);

    const {mdx} = props.data;
    const {body, frontmatter} = mdx;
    const {title, euLogin, bigLogin, category, loginButtonText} = frontmatter;

    return (
        <>
            <HtmlHead pageTitle={title}/>
            <LoginWrapper
                title={title}
                euLogin={euLogin}
                bigLogin={bigLogin}
                buttonText={loginButtonText}
                category={category}
                heroImage={true}
                markdown={body}
            >
                <HeroImage/>

                <div className='px-4 lg:flex lg:stackx-8 lg:mt-12 lg:px-20 max-w-screen-2xl mx-auto content page relative'>
                    {
                        !frontmatter.hideSidebar &&
                        <div className='w-1/4'>
                            <Sidebar category={frontmatter.category}/>
                        </div>
                    }
                    <main className='w-full lg:w-3/4'>
                        <h1 className={'text-4xl font-bold my-6'}>{frontmatter.title}</h1>

                        <div className={'my-8'}>
                            <MDXRenderer
                            >{body}
                            </MDXRenderer>
                        </div>
                    </main>
                </div>
            </LoginWrapper>
            <OneTrustManager id={'33b4c561-473a-4a02-ad83-002e120d0c73'}/>
        </>
    );
};

export default ContentPage;

export const pageQuery = graphql`
    query($slug: String!) {
        mdx(frontmatter: { slug: { eq: $slug } }) {
            body
            frontmatter {
                slug
                title
                category
                hideSidebar
                euLogin
                bigLogin
                loginButtonText
            }
        }
    }
`;

